$(document).ready(function(){

    $('.anchor').click( function() {
        var page = $(this).attr('href');
        var speed = 1200;
        $("#navbar").removeClass("in")
        $('html, body').animate( { scrollTop: $(page).offset().top }, speed );
        return false;
    });

    const i18n = domI18n({
        selector: '[data-translatable]',
        separator: ' // ',
        languages: ['es', 'en'],
        defaultLanguage: 'es'
      });

    $( ".en-btn" ).click(function() {
      i18n.changeLanguage('en');
      $( ".en-btn" ).addClass('active-btn');
      $( ".es-btn" ).removeClass('active-btn');
    });

    $( ".es-btn" ).click(function() {
      i18n.changeLanguage('es');
      $( ".es-btn" ).addClass('active-btn');
      $( ".en-btn" ).removeClass('active-btn');
    });
});
